import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import myMixins from "./plugins/myMixins";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)


//GC PORTAL SOCKET
import io from "socket.io-client";

const socket = io("https://iconntest.hrd-s.com", {
  transport: ["websocket"],
});

Vue.prototype.$socket = socket;
import VueSocketIO from "vue-socket.io";

// Vue.config.productionTip = false

Vue.use(
  new VueSocketIO({
    connection: io("https://iconntest.hrd-s.com", {
      transport: ["websocket"],
    }), //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);








Vue.config.productionTip = false;
Vue.use(myMixins);



// Vue.prototype.$BaseUrl = 'http://10.169.142.45:5001'
Vue.prototype.$BaseUrl = 'https://hnmwjwr2fi.execute-api.ap-southeast-1.amazonaws.com/prod'



export const bus = new Vue(); 

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  devtools: process.env.NODE_ENV !== 'production', 
  plugins: [
    createPersistedState(
      {
      key: 'Hidden-Key', // Provide a unique key to avoid conflicts with other applications
      paths: ['emptyStorage','account'], // Specify the state properties you want to persist
      }
    ),
  ],
  state: {
    emptyStorage:[],
    KansenSystem: [],
    FinishedPlan: [],
    SubconRequest: [],
    account: {},
    awsHeaders: process.env.VUE_APP_AWS_HEADERS,
  },
  mutations: {
    'EMPTY_USER': (state) => {
      state.account = {}
    },
    'STORE_USER': (state, newState) => {
      state.account = newState
    },
    'STORE_KANSENSYSTEMINFO': (state, newState) => {
      state.KansenSystem = newState
    },
    'STORE_FINISHEDPLANINFO': (state, newState) => {
      state.FinishedPlan = newState
    },
    'STORE_SUBCONREQUESTINFO': (state, newState) => {
      state.SubconRequest = newState
    },
  },
  actions: {},
  modules: {},
})


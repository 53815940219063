<template>
  <v-app id="inspire">
      <v-app-bar app  dense permanent persistent flat absolute dark 
                  height="70px" src="@/assets/Navy Blue.png" v-if="account.username != null">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="account.role == 'System Admin' || account.role == 'Kansen Admin'"></v-app-bar-nav-icon>
        <div v-if="account.role != 'Subcon User'" >
          <v-img class=" mr-10 hidden-sm-and-down" contain top  width="450px"
              src="@/assets/kansenSystem.png"/>
        </div>
        <div v-if="account.role == 'Subcon User'" >
          <v-img class=" mr-10 hidden-sm-and-down" contain top  width="450px"
              src="@/assets/subconHeader.png"/>
        </div>
          <v-spacer/>
            
            <span style="margin-right:1%;font-size:14px;margin-top:0.5%">{{account.name}}</span>
            <span v-if="account.role == 'System Admin'" style="margin-right:1%;font-size:14px;margin-top:0.5%">{{'('+ account.role + ')'}}</span>
            <span>
              <v-icon
                  v-if="account.role == 'Subcon User' || account.role == 'System Admin'"
                  size="40" 
                  style="margin-right:20px" 
                  @click="openUserManual()">mdi-information-variant-circle-outline
              </v-icon>
            </span>
              <v-divider vertical style="margin-right:1%" color="white" inset></v-divider>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-if="account.role != 'Subcon User'" v-on="on" @click="logOut()"
                          >mdi-logout
                      </v-icon>
                    </template>
                      <span>Log-out</span>
                </v-tooltip>
                <v-btn small dense color="warning" v-if="account.role == 'Subcon User'" @click="logOut()"><b>ログアウト</b></v-btn>
                <v-tooltip>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="margin-left:3%" size="30" @click="openlistOfUser()" v-if="account.role == 'System Admin'|| account.role == 'Kansen Admin'" >mdi-text-account</v-icon>
                </template>
                <span>List of User</span>
                </v-tooltip>
      </v-app-bar>
    <v-navigation-drawer color="orange" app  v-if="allTypeOfAccounts.includes(account.role)" 
      :mini-variant="drawer" mobile-breakpoint="900">
        <div>
            <v-list-item link :to="item.to"  v-for="(item, i) in filteredDrawerData" :key="i">
                <v-list-item-action >
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="orange" large>{{item.icon}}</v-icon>
                        </template>
                        <span style="font-size: 13px; font-family: Lucida Sans Unicode ; color:white;font-weight:bold">{{item.title}}</span>
                    </v-tooltip>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title style="font-family:Lucida Sans Unicode" class="orange--text">{{item.title}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </div>
    </v-navigation-drawer>

    <v-dialog v-model="listOfUsersDialog" width="1000px">
      <v-card>
        <v-card-text>
          <v-toolbar>
            LIST OF USERS
            <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                      <v-btn fab small style="margin-right:2%" color="success" v-on="on">
                        <v-icon 
                        @click="registerDialog()" 
                        style="margin-right:2%" >
                        mdi-account-multiple-plus</v-icon>
                      </v-btn>
                  </template>Add User
                </v-tooltip>
              <v-text-field style="margin-top:2%" v-model="search" label="Search" small outlined dense clearable></v-text-field>
          </v-toolbar>
          <v-data-table
          class="elevation-5"
          :headers="headers"
          :items="filteredusersData"
          :search="search"
          height="50vh"
          fixed-header
          :items-per-page="itemsPerPage">
          <template v-slot:[`item.No`]="{item}">{{filteredusersData.indexOf(item) + 1}}</template> 


          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  icon v-bind="attrs" v-on="on">
                        <v-icon  color="green" @click="editUser(item)">mdi-account-edit-outline</v-icon>
                    </v-btn>
                </template><span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn   color="red" icon v-bind="attrs" v-on="on">
                        <v-icon @click="deleteUser(item)">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </template><span>Delete</span>
            </v-tooltip>
        </template>
          </v-data-table>
          <v-btn v-if="account.role=='System Admin'" text class="floating-button"  color="warning" @click="toggleListOfSubconUser()"> {{ openSubconUser ? 'hide subcon user' : 'view subcon user' }}</v-btn>
        
          <v-text-field
            v-if="openSubconUser" 
            style="margin-top:2%" 
            v-model="searchForSubconUser" 
            label="Search" 
            small 
            outlined 
            dense 
            clearable>
          </v-text-field>
          <v-data-table
            v-if="openSubconUser"
            class="elevation-5"
            :headers="subconUserHeader"
            :items="subconUser"
            :search="searchForSubconUser"
            height="50vh"
            fixed-header
            :items-per-page="itemsPerPage">
            <template v-slot:[`item.No`]="{item}">{{subconUser.indexOf(item) + 1}}</template> 
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn  icon v-bind="attrs" v-on="on">
                          <v-icon  color="green" @click="restoreUser(item)">mdi-restore</v-icon>
                      </v-btn>
                  </template><span>Restore User</span>
              </v-tooltip>
            </template> -->
          </v-data-table>

           
        </v-card-text>
      </v-card>
    </v-dialog>
  
    <v-dialog v-model="userDialog" width="400px">
            <v-card>
              <v-card-text  >
                <v-toolbar>
                  <v-toolbar-title style="margin-left:25%;font-size:30px">
                      REGISTER
                  </v-toolbar-title>
                </v-toolbar>
                <v-card flat>
                  <v-text-field  @keyup="UpperCase" style="margin-top:4%" :rules="rules" clearable v-model="name" label="Full Name" small outlined dense></v-text-field>
                  <v-autocomplete :rules="rules"  clearable v-model="typeOfaccount"  :items="accountType" label="Account Type"    class="mt-n2" small outlined dense></v-autocomplete>
                  <v-text-field  :rules="rules"  clearable v-model="username"  label="User Name"       class="mt-n2" small outlined dense></v-text-field>
                  <v-text-field  :rules="rules"  clearable v-model="password"   label="Password"        class="mt-n2" small outlined dense></v-text-field>
                </v-card>
                <center>
              <v-btn rounded color="success" @click="addNewUser()"> <v-icon>mdi-plus</v-icon>ADD USER</v-btn>
              </center>
              </v-card-text>
            </v-card>
    </v-dialog>

    <v-dialog v-model="editUserDialog" width="400px">
            <v-card>
              <v-card-text>
                <v-toolbar color="warning">
                  <v-toolbar-title style="margin-left:25%;font-size:30px">
                    USER INFO
                  </v-toolbar-title>
                </v-toolbar>
                <v-card flat>
                  <v-text-field style="margin-top:4%" :rules="rules" clearable v-model="updatedUser.name" label="Full Name" small outlined dense></v-text-field>
                  <v-autocomplete :rules="rules"  clearable v-model="updatedUser.role"  :items="accountType" label="Account Type"    class="mt-n2" small outlined dense></v-autocomplete>
                  <v-text-field  :rules="rules"  clearable v-model="updatedUser.username"  label="User Name"       class="mt-n2" small outlined dense></v-text-field>
                  <v-text-field  :rules="rules"  clearable v-model="updatedUser.password"   label="Password"        class="mt-n2" small outlined dense></v-text-field>
                </v-card>
                <center>
              <v-btn rounded color="warning" @click="UpdateUserInfo()">update info</v-btn>
              </center>
              </v-card-text>
            </v-card>
    </v-dialog>
   

    <v-main><router-view/></v-main>
    <UserManual></UserManual>
    
    
  </v-app>
  
</template>

<script>
import { bus } from './main'
import Swal from "sweetalert2";
// import store from "./store/index";
import UserManual from "./components/SubconUserManual.vue"
import axios from "axios";
export default {
  components :{
    UserManual
  },
  
  name: 'App',
  data: () => ({
    userManualDialog:false,
    permanentAdmin:['16061','08671'],
    updatedUser:{},
    searchForSubconUser:'',
    search:'',
    editUserDialog:false,
    rules: [(v) => !!v || "Field is required"],
    itemsPerPage: 100,
    listOfUsersDialog:false,
    openSubconUser:false,
    usersData:[],
    subconUser:[],
    filteredusersData:[],
    deletedUsersData:[],
    // employeeNo:'',
    name:'',
    username:'',
    password:'',
    typeOfaccount:'',
    accountType:['Kansen User','Kansen Admin'],
    allTypeOfAccounts:['Kansen User','Kansen Admin','System Admin'],
    userDialog:false,
    UserName:'',
    drawer:true,
    drawerData: [
        {title:'Home' ,             icon:'mdi-alpha-h', to :'/Kansensystem'},
        {title:'FINISHED PLAN' ,    icon:'mdi-alpha-f', to :'/Finishedplan'},
        {title:'SUBCON REQUEST' ,   icon:'mdi-alpha-s', to :'/Subconrequest'},
    ],
    navigation:null,

    headers: [
        { text: 'No.',            sortable : false,value: 'No',           align:'center', width:"10px"},
        { text: "Name",         sortable : false,   value: "name",      align: "center",    width:"200px"},
        { text: "Action",       value: "actions",   align: "center",    width:"150px",     class: "white--text",},
        { text: "Account Type", value: "role",      align: "center",    width:"200px",},
        { text: "User Name",    sortable : false,   value: "username",  align: "center",  },
        { text: "Password",     sortable : false,   value: "password",  align: "center",  },
        ],
    subconUserHeader:[
        { text: "Action",       value: "actions",   align: "center",    width:"150px",     class: "white--text",},
        { text: "Subcon Name", value: "subconName",      align: "center",    width:"200px",},
        { text: "User Name",    sortable : false,   value: "username",  align: "center",  },
        { text: "Password",     sortable : false,   value: "password",  align: "center",  },
    ]    
  }),
  computed: {
    filteredDrawerData() {
    return this.drawerData .filter(el => el.title != 'SUBCON REQUEST' && this.accountType.includes(this.account.role) || this.account.role == 'System Admin')
    
    }
  },
  methods:{
    openUserManual(){
      this.userManualDialog = true
      bus.$emit('userManualDialog')
   
    },
  
    toggleListOfSubconUser() {
      this.openSubconUser = !this.openSubconUser;
      this.loadsubconUsers()
    },
    UpperCase(){
        this.name = this.name.toUpperCase()
    },
    
    editUser(item){
      this.editUserDialog = true
      this.updatedUser = item
    },
    async openlistOfUser(){
      this.listOfUsersDialog = true
      const headers = { 'x-api-key' : this.awsHeaders };
          let existingUserData = await axios.get(`${this.$BaseUrl}/api/getallKansenUser`, { headers });
          let newArray = existingUserData.data.filter(el => el.role != 'Subcon User' && el.role != 'System Admin')
         
          if(this.account.role =='Kansen Admin'){
            this.filteredusersData = newArray
          }else if (this.account.role == 'System Admin'){
            let allUsers = await axios.get(`${this.$BaseUrl}/api/getOverallUser`, { headers });
            this.filteredusersData = allUsers.data
          }
          
    },

    clearRegistration(){
      // this.employeeNo = ''
      this.name = ''
      this.username = ''
      this.password = ''
      this.typeOfaccount = ''
      this.userDialog = false
      this.editUserDialog = false
      this.listOfUsersDialog = false
    },


    
    registerDialog(){
      this.userDialog = true
    },

    addNewUser(){
        let object = {
          name:  this.name,
          username: this.username,
          password : this.password,
          role : this.typeOfaccount,
          registered_by : this.account.name
        }
        const existingUsername = this.usersData.filter(el=> el.username == object.username)
          if(existingUsername.length > 0){
              Swal.fire({
                              toast: true,
                              position: 'top',
                              icon: 'warning',
                              title: 'User name already exist!',
                              showConfirmButton: false,
                              timer: 1500,
                              customClass: {
                              popup: 'my-toast'
                        }     
                              })
          }else{
                axios({
                        method:'post',
                        url:`${this.$BaseUrl}/api/InsertUser`,
                        data:object,
                        headers: {
                                            'x-api-key' : this.awsHeaders
                                        }
                }).then(() => {
                  Swal.fire({
                        toast: true,
                        position: 'bottom',
                        icon: 'success',
                        title: 'User Added',
                        showConfirmButton: false,
                        timer: 1500,
                        customClass: {
                        popup: 'my-toast'
                    }
                        })
                }).catch((err) => {
                  console.log(err);
                });  
          }
          this.clearRegistration()
    },

    logOut(){
      if(this.account.role == 'Subcon User'){
        Swal.fire({
                    text: "ログアウトしてもよろしいですか？",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: '確認する',
                    cancelButtonText: 'キャンセル',
                    width: '350px',
                    }).then((result) => {
                        if (result.isConfirmed) {
                        Swal.fire({
                            toast: true,
                            position: 'bottom',
                            icon: 'warning',
                            title: 'Log Out Confirm!',
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                            popup: 'my-toast'
                        }
                            })
                            this.$store.commit("EMPTY_USER");
                            this.$router.push("/Login")
                        }
                        })
      }else{
        Swal.fire({
                    text: "Are you sure you want to log-out?",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    width: '350px',
                    }).then((result) => {
                        if (result.isConfirmed) {
                        Swal.fire({
                            toast: true,
                            position: 'bottom',
                            icon: 'warning',
                            title: 'Log Out Confirm!',
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                            popup: 'my-toast'
                        }
                            })
                            this.$store.commit("EMPTY_USER");
                            this.$router.push("/Login")
                        }
                        })
      }
        
},

UpdateUserInfo(){
      let updatedObj= {  
                          name :      this.updatedUser.name,
                          username :  this.updatedUser.username, 
                          password :  this.updatedUser.password,
                          role :      this.updatedUser.role,
                          id:         this.updatedUser.id 
                      }
                axios({
                            method:'post',
                            url:`${this.$BaseUrl}/api/updateUserAccount`,
                            data:updatedObj,
                            headers: {
                                                'x-api-key' : this.awsHeaders
                                            }
                        })  
            Swal.fire({
                          toast: true,
                          position: 'bottom',
                          icon: 'success',
                          title: 'Update Success!',
                          showConfirmButton: false,
                          timer: 1500,
                          customClass: {
                          popup: 'my-toast'
                          }
                            })
              this.clearRegistration()
        },

  deleteUser(item){
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const hours = today.getHours().toString().padStart(2, '0');
            const minutes = today.getMinutes().toString().padStart(2, '0');
            const seconds = today.getSeconds().toString().padStart(2, '0');
            const formattedTime = `${hours}:${minutes}:${seconds}`;
            const dateTime = date + ' | ' + formattedTime;
  let Obj = {
    deleted_by : "name: " + this.account.name + ', username: ' + this.account.username,
    deleted_date : dateTime,
    id:item.id
  }

  Swal.fire({
                icon:'question',
                title: 'Delete user <br>' + item.name + '?',
                html: "Are you sure you want to delete this user?",
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText : 'Cancel',
                allowOutsideClick: () => {
                const popup = this.$swal.getPopup()
                popup.classList.remove('swal2-show')
                return false}
            }).then((result) => {
                if (result.isConfirmed) {
                  axios({
                            method:'post',
                            url:`${this.$BaseUrl}/api/deleteUserAccount`,
                            data:Obj,
                            headers: {
                                      'x-api-key' : this.awsHeaders
                                            }
                        })  
                        Swal.fire({
                                        toast: true,
                                        position: 'bottom',
                                        icon: 'success',
                                        title: 'User Deleted!',
                                        showConfirmButton: false,
                                        timer: 1500,
                                        customClass: {
                                        popup: 'my-toast'
                                    }
                                        })
                          location.reload()              
                          this.clearRegistration()
                      
                }
            })
            
        },   

  restoreUser(item){
    let iD ={
    id :item.id
  }
    Swal.fire({
                icon:'question',
                title: 'Restore user <br>' + item.name + '?',
                html: "Are you sure you want to restore this user?",
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText : 'Cancel',
                allowOutsideClick: () => {
                const popup = this.$swal.getPopup()
                popup.classList.remove('swal2-show')
                return false}
            }).then((result) => {
                if (result.isConfirmed) {
                  axios({
                                method:'post',
                                url:`${this.$BaseUrl}/api/restoreDeletedUser`,
                                data: iD,
                                headers: {
                                                    'x-api-key' : this.awsHeaders
                                                }
                            })    
                        Swal.fire({
                                        toast: true,
                                        position: 'bottom',
                                        icon: 'success',
                                        title: 'Restore Success',
                                        showConfirmButton: false,
                                        timer: 1500,
                                        customClass: {
                                        popup: 'my-toast'
                                    }
                                        })
                          this.listOfUsersDialog = false
                          this.loadUsers()
                                    
                              
                }
            })  
            },



  loadsubconUsers(){
      axios({
                method: 'get',
                url: `${this.$BaseUrl}/api/getallSubconUserAccount`,
                headers: {
                  'x-api-key': this.awsHeaders
                }
              }).then(res => {
                this.subconUser = res.data
              })
  },      


  },
  watch:{
    navigation(){
      this.drawer = false
    }
  },
  mounted() {
    // this.loadUsers()
    // this.loadDeletedUsers()
  },

 
};
</script>
<style>
.v-list-item:hover {
  background-color: rgb(246, 245, 244);
}
.v-navigation-drawer {
  background: linear-gradient(to bottom right, hsl(227, 33%, 21%), #20202d);
}

.floating-button {
  position: absolute;
  left: 16px;
  bottom: 45px;
}

</style>
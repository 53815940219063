import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },

  {
    path: '/Kansensystem',
    name: 'Kansensystem',
    component: () => import('../views/Kansensystem.vue')
  },

  {
    path: '/Finishedplan',
    name: 'Finishedplan',
    component: () => import('../views/Finishedplan.vue')
  },

  {
    path: '/Subconrequest',
    name: 'Subconrequest',
    component: () => import( '../views/Subconrequest.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




router.beforeEach((to,from,next)=>{
  if (to.path !== '/Login') {
    if (store.state.account.role != undefined) {
      next()
    } else {
        if (store.state.account.username != undefined) {
        next()
        } else{
        next('/Login')
        }
    }
  }else{
  next()
  }
  })



// router.beforeEach((to, from, next) => {
//   if (to.path !== '/Login') {
//     const userRole = store.state.account.role;

//     if (userRole === 'Kansen Admin' || userRole === 'Kansen User') {
//       // Allow access to Kansensystem and Finishedplan routes
//       if (to.name === 'Kansensystem' || to.name === 'Finishedplan') {
//         next();
//       } else {
//         // Redirect to unauthorized page or another route
//         next('/unauthorized');
//       }
//     } else if (userRole === 'System Admin') {
//       // Allow access to all routes for System Admin
//       next();
      
//     } else if(userRole === 'Subcon User') {
//       if (to.name === 'Subconrequest') {
//         next();
//       } else {
//         // Redirect to unauthorized page or another route
//         next('/unauthorized');
//       }
//     }else{
//       next('/Login');
//     }
//   } else {
//     next();
//   }
// }); 
















export default router

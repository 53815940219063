import {
    mapState,
    mapMutations
} from 'vuex'

const myPlugins = {
    install(Vue) {
        Vue.mixin({
            data: () => ({
                api: process.env.VUE_APP_URL,
                // awsHeaders : process.env.VUE_APP_AWS_HEADERS
            }),
            computed: {
                ...mapState([
                    'Kansensystem',
                    'FinishedPlan',
                    'SubconRequest',
                    'account',
                    'awsHeaders'
                ]),
            },
            methods: {
                ...mapMutations([
                    'STORE_KANSENSYSTEMINFO',
                    'STORE_FINISHEDPLANINFO',
                    'STORE_SUBCONREQUESTINFO',
                    'STORE_USER'
                ])
            }
        })
    }
}

export default myPlugins